@import 'fonts.css';

/*
*
* ERGO ELEMENTS - Modification
*
 */
.ee_form-row__descriptor {
  font-size: 100%;
  font-weight: bolder;
}

.ee_form-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.ee_panel {
  padding: 5px 15px !important;
  margin-top: 3rem;
}

h2.esc_h4 {
  line-height: 1 !important;
  margin: .8rem 0 .5rem !important;
}

h2.esc_h3 {
    margin: .8rem 0 .5rem !important;
}

.esc_simple-table th {
  text-align: left !important;
}

.esc_simple-table td {
  text-align: left !important;
  vertical-align: top !important;
  background-color: inherit !important;
}

.esc_simple-table tbody>tr:nth-child(even) {
  background-color: #f2f2f2 !important;
}

.ee_button--tertiary {
  background-color: #fff !important;
}

.ee_button--tertiary:hover {
  color: #fff !important;
  background: #737373 !important;
  border-color: #737373 !important;
}

.ee_button--tertiary:active {
  color: #fff !important;
  background: #737373 !important;
  border-color: #737373 !important;
}

.ee_button--tertiary:focus {
  color: #fff !important;
  background: #737373 !important;
  border-color: #737373 !important;
}

.ee_radio-tile {
  width: 90% !important;
}

.ee_radio-tile__wrapper {
  padding-top: 10px;
}

.ee_radio-tile__content {
  text-align: left !important;
  padding: 1.5rem !important;
}

.ee_radio-tile__check-mark {
  fill: transparent;
}

/*
*
* Allgemein
*
 */

.capitalize {
  text-transform: capitalize;
}

.position-relative {
  position: relative;
}

.float-right {
  float: right;
}

/*
*
* Components
*
 */
.exceptions {
  background-color: #e4d6e4;
  color: #71022e;
  margin-left: 1.5rem;
  margin-top: 10rem !important;
}

.exceptions-details {
  padding-bottom: 10px;
  margin-top: 10px;
}

.exceptions-details-content {
  /*padding-bottom: 10px;*/
  padding-top: 50px;
}

.exceptions-details > .ee_button {
  color: #71022e !important;
  float: right;
  padding-bottom: 15px;
}

.angebot-laden {
  color: #71022e;
  margin-left: 1.5rem;
  margin-top: 10rem !important;
}

.angebot-laden-icon {
  float: left;
  margin-top: -5px;
  margin-right: 20px;
  fill: #71022e;
}

.sidebar {
  border-left: solid 1px lightgray;
  height: 100%;
  width: 20%;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
}

.sidebar-scroll {
  overflow-y: scroll;
  height: calc(100vh - 200px);
}

.personen-block .rolle > .ee_button {
  margin-bottom: .5rem;
}

.personen-block .rolle > .ee_button.ee_button--primary {
  background: #71022e;
  border-color: #71022e;
  color: #ffffff;
}
.personen-block .rolle > .ee_button.ee_button--primary:disabled {
  background: #71022e !important;
  opacity: 0.75;
  border-color: #71022e !important;
  color: #ffffff !important;
}

.personen-block-delete > .ee_button {
  padding: 0 !important;
  width: 36px !important;
  margin-top: 4px !important;
}

.sidebar-scroll > .ee_expand-animation.ee_expand-animation--open {
    height: fit-content !important;
}

.personensuche-personenanzeige {
  margin-top: 20px;
}

.personensuche-personenanzeige > .ee_button {
  border-radius: 8px !important;
  border-width: 0 !important;
  width: 92%;
  align-items: start !important;
  justify-content: start !important;
  text-align: left !important;
  padding: 12px 8px !important;
  font-weight: normal !important;
}

.personensuche-personenanzeige .ee_button > .ee_button__wrapper {
  width: 100% !important;
}

.personensuche-messageBox {
  padding: 8px !important;
  margin-top: 3px !important;
}

.component-content {
  padding: 20px 15px;
}

.checkbox-distance {
    margin-bottom: -24px;
}

.ergo-logo {
  width: 8rem;
  padding: 10px 0;
}

.bankverbindung > .ee_form-row__messages.ee_form-row__messages--wide {
  padding-left: 0 !important;
}

.personensuche > .ee_form-row__messages.ee_form-row__messages--wide {
  padding-left: 0 !important;
}

.versicherungsschutz {
  position: relative;
}

.versicherungsschutz > .ee_loading-spinner {
  height: 102% !important;
  margin-left: -20px !important;
}

.versicherungsschutz .premiumbox  {
  margin-top: 30px !important;
}

.versicherungsschutz .premiumbox .ee_premium-box__label {
  font-size: 22px !important;
}

.versicherungsschutz-beitragsinfo {
  width: 380px;
}

.versicherungsschutz .ee_card.ee_card--with-legend.ee_card--normal-padding {
  margin-bottom: 0.0rem;
}

.abschluss-hinweis .ee_checkbox__label {
  padding-top: 0.05em !important;
  padding-bottom: 0.05em !important;
}

.abschluss-hinweis .hinweis-bold  {
  font-weight: bold !important;
  padding-top: 0.05em !important;
  padding-bottom: 0.05em !important;
}

.abschluss-hinweis .ee_checkbox__box {
  align-self: flex-start;
}

.ee_salesman-button__text{
  font-size: 1em !important;
}

::placeholder {
  color: lightgrey;
  opacity: 1;
}

.ee_form-row__messages.ee_form-row__messages--wide {
  padding-left: 0 !important;
  padding-right: 1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance:  none;
  margin: 0;
}

#delete-button > span.ee_button__wrapper {
  padding: 0;
  border-radius: 50%;
}

#delete-button-content {
  display: flex;
}

#tax-modal .ee_autocomplete {
  height: 100px;
}

#tax-modal .ee_autocomplete__menu {
  max-height: 150px !important;
}

#tax-modal .ee_modal__container{
  max-width: 45rem;
}

.tax-modal-container .ee_input-adornment {
  pointer-events: none;
}
